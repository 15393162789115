<template>

    <section>

      <!-- card collapsible  -->
      
        <b-card-actions  :title="connectionStatus" action-collapse>
            <b-row>
                <b-col cols="1">
                    <template v-if="!isUserConnected">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="connectWeb3Modal">
                            Connect
                        </b-button>
                    </template>
                    <template v-else>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="disconnectWeb3Modal">
                            Disconnect
                        </b-button>
                    </template>
                </b-col>
                <b-col cols="4" v-if="isUserConnected">                    
                    <b-form-select v-model="address" :options="availableAddress" />
                </b-col>
            </b-row>

            <b-row class="pt-1">
                <!-- first name -->
                <b-col cols="4">
                    <b-form-group label="PublicRoundSold">
                        <b-input-group class="input-group-merge">                        
                            <b-form-input v-model="state.publicSold" readonly />                            
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="IsPublicSaleActive">
                        <b-input-group class="input-group-merge">                        
                            <b-form-input :value="t(state.isPublicMintActive)" placeholder="Wallet address" readonly />
                            <ButtonSpinner variant="danger" @click.native="togglePublicMintActive" :isLoading="publicSetting" :disabled="contract===null"> Toggle</ButtonSpinner>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="publicMintPrice">
                        <b-input-group class="input-group-merge">                        
                            <b-form-input v-model="state.publicMintPrice" :readonly="state.isPublicMintActive"/>
                            <ButtonSpinner variant="danger" @click.native="setPublicMintDetail" :isLoading="publicSetting"  :disabled="contract===null || state.isPublicMintActive">Set</ButtonSpinner>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="maxPublicMintAmount">
                        <b-input-group class="input-group-merge">                        
                            <b-form-input v-model="state.maxPublicMintAmount" :readonly="state.isPublicMintActive"/>
                            <ButtonSpinner variant="danger" @click.native="setPublicMintDetail" :isLoading="publicSetting" :disabled="contract===null || state.isPublicMintActive">Set</ButtonSpinner>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="maxPublicRoundSupply">
                        <b-input-group class="input-group-merge">                        
                            <b-form-input v-model="state.maxPublicRoundSupply" :readonly="state.isPublicMintActive"/>
                            <ButtonSpinner variant="danger" @click.native="setPublicMintDetail" :isLoading="publicSetting"  :disabled="contract===null || state.isPublicMintActive">Set</ButtonSpinner>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group label="PrivateRoundSold">
                        <b-input-group class="input-group-merge">                        
                            <b-form-input v-model="state.privateSold" readonly />                            
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="IsPrivateSaleActive">
                        <b-input-group class="input-group-merge">                        
                            <b-form-input :value="t(state.isPrivateMintActive)" placeholder="Wallet address" readonly />
                            <ButtonSpinner variant="info" @click.native="togglePrivateMintActive" :isLoading="privateSetting" :disabled="contract==null">Toggle</ButtonSpinner>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="privateMintPrice">
                        <b-input-group class="input-group-merge">                        
                            <b-form-input v-model="state.privateMintPrice" :readonly="state.isPrivateMintActive"/>
                            <ButtonSpinner variant="info" @click.native="setPrivateMintDetail" :isLoading="privateSetting" :disabled="contract===null || state.isPrivateMintActive">Set</ButtonSpinner>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="maxPrivateMintAmount">
                        <b-input-group class="input-group-merge">                        
                            <b-form-input v-model="state.maxPrivateMintAmount" :readonly="state.isPrivateMintActive"/>
                            <ButtonSpinner variant="info" @click.native="setPrivateMintDetail" :isLoading="privateSetting"  :disabled="contract===null || state.isPrivateMintActive">Set</ButtonSpinner>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="maxPrivateRoundSupply">
                        <b-input-group class="input-group-merge">                        
                            <b-form-input v-model="state.maxPrivateRoundSupply" :readonly="state.isPrivateMintActive"/>
                            <ButtonSpinner variant="info" @click.native="setPrivateMintDetail" :isLoading="privateSetting"  :disabled="contract===null || state.isPrivateMintActive">Set</ButtonSpinner>
                        </b-input-group>
                    </b-form-group>

                </b-col>
                <b-col cols="4">
                    <b-form-group label="VoucherSold">
                        <b-input-group class="input-group-merge">                        
                            <b-form-input v-model="state.voucherSold" readonly />                            
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="IsVoucherSaleActive">
                        <b-input-group class="input-group-merge">                        
                            <b-form-input :value="t(state.isVoucherMintActive)" readonly />
                            <ButtonSpinner variant="primary" @click.native="toggleVoucherMintActive" :isLoading="voucherSetting" :disabled="contract===null">Toggle</ButtonSpinner>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="Minted Address">
                        <b-input-group class="input-group-merge">                        
                            <b-form-input v-model="mintedAddress" placeholder="Set minted address"  />
                            <ButtonSpinner variant="primary" @click.native="setMintedAddress" :isLoading="voucherSetting" :disabled="contract===null">Set</ButtonSpinner>
                            <ButtonSpinner variant="info" @click.native="checkMintedAddress" :isLoading="voucherSetting" :disabled="contract===null">Check</ButtonSpinner>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="Withdraw Address">
                        <b-input-group class="input-group-merge">                        
                            <b-form-input v-model="withdrawAddress" placeholder="Set minted address" />
                            <ButtonSpinner variant="primary" @click.native="setWithdrawAddress" :isLoading="voucherSetting" :disabled="contract===null">Set</ButtonSpinner>
                            <ButtonSpinner variant="info" @click.native="checkWithdrawAddress" :isLoading="voucherSetting" :disabled="contract===null">Check</ButtonSpinner>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="Contract Balance">
                        <b-input-group class="input-group-merge">                        
                            <b-form-input :value="state.balance" readonly/>
                            <ButtonSpinner variant="primary" @click.native="withdraw" :isLoading="voucherSetting" :disabled="contract===null">Withdraw</ButtonSpinner>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>


        </b-card-actions>
        <b-tabs>
            <b-tab active>
                <template #title>
                    <feather-icon icon="HomeIcon" />
                    <span>Mint</span>
                </template>

                <b-form @submit.prevent>
                    <b-row>

                    <!-- password -->
                    <b-col cols="3">
                        <b-form-group label="Mint Amount" label-for="vi-password" >
                        <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text><feather-icon icon="HashIcon" /></b-input-group-prepend>
                            <b-form-input  v-model="mintAmount" type="number" placeholder="Joker Amount" />
                        </b-input-group>
                        </b-form-group>
                    </b-col>
                    <!-- reset and submit -->
                    <b-col cols="12">
                        <ButtonSpinner variant="danger" class="mr-1" :isLoading="minting" :disabled="getContract === null" @click.native="publicMint" loadMessage="Minting">
                            Public Mint
                        </ButtonSpinner>                    
                        <ButtonSpinner variant="info" class="mr-1" :isLoading="minting" :disabled="getContract === null" @click.native="privateMint" loadMessage="Minting">
                            Private Mint
                        </ButtonSpinner>
                        <ButtonSpinner variant="primary" class="mr-1" :isLoading="minting" :disabled="getContract === null" @click.native="voucherMint" loadMessage="Minting">
                            Voucher Mint
                        </ButtonSpinner>
                    </b-col>      
                    </b-row>
                </b-form>

            </b-tab>

            <b-tab>
                <template #title>
                    <feather-icon icon="ToolIcon" />
                    <span>Whitelisted</span>
                </template>

                <b-form @submit.prevent>
                    <b-row>
                        <b-col cols="12">
                            <b-form-group label="Address to whitelisted" >
                                <b-input-group class="input-group-merge">
                                    <b-form-textarea  v-model="whitelistedAddress" placeholder="Addresses seperate by ; or new line" rows="4" max-rows="8" />
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <!-- reset and submit -->
                        <b-col cols="12">
                            <ButtonSpinner variant="primary" class="mr-1" :isLoading="whitelisting" :disabled="getContract === null" @click.native="addWhitelistedAddress">
                                Add Whitelist
                            </ButtonSpinner>                            
                            <ButtonSpinner variant="danger" class="mr-1" :isLoading="whitelisting" :disabled="getContract === null" @click.native="clearWhitelistedAddress">
                                Reset
                            </ButtonSpinner>
                        </b-col>

                        <b-col cols="6" class="pt-1">
                            <b-form-group label="Address to check" label-for="vi-email" >
                                <b-input-group class="input-group-merge">
                                    <b-input-group-prepend is-text> <feather-icon icon="GridIcon" /> </b-input-group-prepend>
                                    <b-form-input v-model="whitelisedCheckAddress" placeholder="Address"/>
                                    <ButtonSpinner variant="danger" class="mr-1" :disabled="getContract === null" @click.native="checkWhitelistedAddress">
                                        Check
                                    </ButtonSpinner>                                    
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>

                </b-form>

            </b-tab>

            <b-tab>
                <template #title>
                    <feather-icon icon="SettingsIcon" />
                    <span>Voucher</span>
                </template>                

                <b-form @submit.prevent>
                    <b-row>
                        <b-col cols="12">
                            <b-form-group label="Voucher Detail (address:amount)" >
                                <b-input-group class="input-group-merge">
                                    <b-form-textarea  v-model="voucherAddress" placeholder="Addresses:amount seperate by ; or new line" rows="4" max-rows="8" />
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <!-- reset and submit -->
                        <b-col cols="12">
                            <ButtonSpinner variant="primary" class="mr-1" :isLoading="whitelisting" :disabled="getContract === null" @click.native="addVoucherAddress">
                                Add Voucher
                            </ButtonSpinner>                            
                            <ButtonSpinner variant="danger" class="mr-1" :isLoading="whitelisting" :disabled="getContract === null" @click.native="clearVoucherAddress">
                                Reset
                            </ButtonSpinner>
                        </b-col>

                        <b-col cols="6" class="pt-1">
                            <b-form-group label="Address to check" label-for="vi-email" >
                                <b-input-group class="input-group-merge">
                                    <b-input-group-prepend is-text> <feather-icon icon="GridIcon" /> </b-input-group-prepend>
                                    <b-form-input v-model="voucherCheckAddress" placeholder="Address"/>
                                    <ButtonSpinner variant="danger" class="mr-1" :disabled="getContract === null" @click.native="checkVoucherAddress">
                                        Check
                                    </ButtonSpinner>                                    
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>

            </b-tab>

        </b-tabs>

    </section>

</template>

<script>

import { BForm, BTabs, BTab, BCardText, BButton, BFormSelect, BCol, BRow, BFormGroup, BInputGroup, BInputGroupPrepend, BFormInput, BFormTextarea } from 'bootstrap-vue'

import { ethers } from 'ethers'

import { mapActions, mapGetters } from 'vuex'

import Ripple from 'vue-ripple-directive'

import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

import VoucherMintView from '../voucher/VoucherMint.vue'
import VoucherUpdateView from '../voucher/VoucherUpdate.vue'
import VoucherDetailView from '../voucher/VoucherDetail.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ButtonSpinner from '@core/components/button-spinner/ButtonSpinner.vue'

import Contract from '@/contract/PublicPrivateVoucherMint.json'


export default {
    data: function() {
        return {
            connectionStatus: "",
            availableAddress: [ ],
            address: "",
            contract: null,
            state: { },
            mintedAddress: "",
            withdrawAddress: "",
            whitelistedAddress: "",
            whitelisedCheckAddress: "",
            voucherAddress: "",
            voucherCheckAddress: "",
            mintAmount: 1,
            minting: false,
            publicSetting: false,
            privateSetting: false,
            voucherSetting: false,
            whitelisting: false,
        }
    },
    computed: {
        ...mapGetters("web3",["isUserConnected","getActiveAccount","getChainName","getChainId"]),
        ...mapGetters("PublicPrivateVoucherMint", ["getContractMessage", "getContract", "getContractState"] ),
    },
    methods: {
        ...mapActions("web3",["connectWeb3Modal","disconnectWeb3Modal","initWeb3Modal"]),
        ...mapActions("PublicPrivateVoucherMint", ["initContract", "updateStatus"] ),
        ...mapActions("logs", ["logToast"]),
        t(val) { return (val) ? "Active" : "Closed"; }, 
        async togglePublicMintActive() {
            this.publicSetting = true
            await this.run("togglePublicMintActive",
                this.contract.togglePublicMintActive()
            )
            this.updateContractState()
            this.publicSetting = false
        },
        async togglePrivateMintActive() {
            this.privateSetting = true
            await this.run("togglePrivateMintActive",
                this.contract.togglePrivateMintActive()
            )
            this.updateContractState()
            this.privateSetting = false
        },
        async toggleVoucherMintActive() {
            this.voucherSetting = true
            await this.run("toggleVoucherMintActive",
                this.contract.toggleVoucherMintActive()
            )
            this.updateContractState()
            this.voucherSetting = false
        },
        async setMintedAddress() {
            try {
                console.log(this.mintedAddress)
                ethers.utils.getAddress(this.mintedAddress)
            } catch (e) {
                console.log(e)
                this.showToast('danger', 'Invalid address', 'Minted address is invalid')
                return
            }
            this.voucherSetting = true
            await this.run("setMintedAddress",
                this.contract.setMintedAddress(this.mintedAddress)
            )
            this.voucherSetting = false
        },
        async checkMintedAddress() {
            try {
                console.log(this.mintedAddress)
                ethers.utils.getAddress(this.mintedAddress)
            } catch (e) {
                console.log(e)
                this.showToast('danger', 'Invalid address', 'Minted address is invalid')
                return
            }            
            try {
                let result = await this.contract.checkMintedAddress(this.mintedAddress)
                alert(result)
            } catch (e) {
                console.log(e)
                this.showToast('danger', 'Transaction failed', e.message)
            }
        },
        async setWithdrawAddress() {
            try {
                ethers.utils.getAddress(this.withdrawAddress)
            } catch (e) {
                this.showToast('danger', 'Invalid address', 'Withdraw address is invalid')
                return
            }
            this.voucherSetting = true
            await this.run("setWithdrawAddress",
                this.contract.setWithdrawAddress(this.withdrawAddress)
            )
            this.updateContractState()
            this.voucherSetting = false
        },
        async checkWithdrawAddress() {
            try {
                console.log(this.withdrawAddress)
                ethers.utils.getAddress(this.withdrawAddress)
            } catch (e) {
                console.log(e)
                this.showToast('danger', 'Invalid address', 'Withdraw address is invalid')
                return
            }
            try {
                let result = await this.contract.checkWithdrawAddress(this.withdrawAddress)
                alert(result)
            } catch (e) {
                console.log(e)
                this.showToast('danger', 'Transaction failed', e.message)
            }
        },
        async withdraw() {
            if (this.contract === null) {
                this.showToast('danger', 'Invalid contract', 'Contract is null')
                return
            }    
            this.voucherSetting = true
            await this.run("withdraw",
                this.contract["withdraw()"]()
            )
            this.updateContractState()
            this.voucherSetting = false
        },
        async setPublicMintDetail() {
            this.publicSetting = true            
            let mintPrice = ethers.BigNumber.from(1e18/1e8).mul(this.state.publicMintPrice*1e8)            
            await this.run("setPublicMintDetail", 
                this.contract.setPublicMintDetail(mintPrice, this.state.maxPublicMintAmount, this.state.maxPublicRoundSupply)
            )
            this.updateContractState()
            this.publicSetting = false
        },
        async setPrivateMintDetail() {
            this.privateSetting = true            
            let mintPrice = ethers.BigNumber.from(1e18/1e8).mul(this.state.privateMintPrice*1e8)            
            await this.run("setPrivateMintDetail", 
                this.contract.setPrivateMintDetail(mintPrice, this.state.maxPrivateMintAmount, this.state.maxPrivateRoundSupply)
            )
            this.updateContractState()
            this.privateSetting = false
        },
        async publicMint() {    
            this.minting = true            
            let value = ethers.BigNumber.from(1e18/1e8).mul(this.state.publicMintPrice*1e8).mul(this.mintAmount)
            await this.run("PublicMint",
                this.contract.publicMint(this.mintAmount,{ value: value })
            )
            this.updateContractState()
            this.minting = false
        },
        async privateMint() {
            this.minting = true            
            let value = ethers.BigNumber.from(1e18/1e8).mul(this.state.privateMintPrice*1e8).mul(this.mintAmount)
            await this.run("PublicMint",
                this.contract.privateMint(this.mintAmount,{ value: value})
            )
            this.updateContractState()
            this.minting = false
        },
        async voucherMint() {
            this.minting = true            
            await this.run("PublicMint",
                this.contract.voucherMint(this.mintAmount)
            )
            this.updateContractState()            
            this.minting = false
        },
        updateConnectionStatus: async function() {
            // await this.initContract()
            if (!this.isUserConnected) {
                this.connectionStatus = `Not connected to wallet; Please connected`
            } else {
                this.connectionStatus = `Connected to ${this.getChainName}[${this.getChainId}] ${this.getContractMessage}: ${this.getActiveAccount}`
            }                                            
            let availableAddress = [ ]
            let network = Contract.network[this.getChainId];
            if (network === undefined) return
            let n = network.address.length;
            for (let i = 0; i < n; ++i) {
                availableAddress.push({ value: network.address[i], text: `${network.name[i]}: ${network.address[i]}` })
            }
            this.address = network.address[0]
            this.availableAddress = availableAddress
        },
        async updateContractState() {
            await this.initContract(this.address) 
            await this.updateStatus(this.address)                       
            this.contract = this.getContract[this.address]
            this.state = this.getContractState[this.address]
            this.state.publicSold = `Current(${this.state.currentPublicRoundSold}) + Past(${this.state.pastPublicRoundSold}) = Total(${this.state.pastPublicRoundSold + this.state.currentPublicRoundSold})`
            this.state.privateSold = `Current(${this.state.currentPrivateRoundSold}) + Past(${this.state.pastPrivateRoundSold}) = Total(${this.state.pastPrivateRoundSold + this.state.currentPrivateRoundSold})`
            this.state.voucherSold = `Unclaimed(${this.state.totalUnclaimedVoucher}) + Claimed(${this.state.totalVoucherClaimed}) = Total(${this.state.totalUnclaimedVoucher + this.state.totalVoucherClaimed})`            
        },
        async addWhitelistedAddress() {
            let address = []
            this.whitelistedAddress.split('\n').forEach((line)=>{
                line.split(';').forEach((addr)=>{
                    addr = addr.trim()
                    if (addr !== "") address.push(addr)
                })            
            })
            if (address.length === 0) return this.showToast('danger', 'Whitelisted address is empty')            
            console.log(address)
            for (let i = 0; i < address.length; i++) {
                try {
                    address[i]  = ethers.utils.getAddress(address[i])
                } catch (e) {
                    return this.showToast('danger', 'Invalid address', `Address(${address[i]}) is invalid`)                    
                }
            }
            this.whitelisting = true
            await this.run("",
                this.contract.addWhitelistedAddresses(address)
            )
            this.whitelisting = false
        },
        async clearWhitelistedAddress() {
            this.whitelistedAddress = ""
        },
        async checkWhitelistedAddress() {
            this.whitelisedCheckAddress = this.whitelisedCheckAddress.trim()
            try   { ethers.utils.getAddress(this.whitelisedCheckAddress) }
            catch { return this.showToast('danger', 'Invalid address', `Address(${this.whitelisedCheckAddress}) is invalid`) }
            let ret = await this.contract.isWhitelisted(this.whitelisedCheckAddress)
            alert(ret)
        },
        async addVoucherAddress() {
            let address = []
            let amount  = []
            let error   = ""
            this.voucherAddress.split('\n').forEach((line)=>{
                line.split(';').forEach((item)=>{
                    item = item.trim()
                    if (item === "") return;
                    let d = item.split(':')
                    if (d.length !== 2) error = "Invalid formant"
                    address.push(d[0].trim())    
                    amount.push(d[1].trim())                                    
                })            
            })
            if (address.length === 0) return this.showToast('danger', 'Voucher address is empty')            
            if (error !== "") return this.showToast('danger', error)
            console.log(address)
            console.log(amount)
            for (let i = 0; i < address.length; i++) {
                try {
                    address[i]  = ethers.utils.getAddress(address[i])
                } catch (e) {
                    return this.showToast('danger', 'Invalid address', `Address(${address[i]}) is invalid`)                    
                }
            }
            this.whitelisting = true
            await this.run("",
                this.contract.addVoucherAddresses(address, amount)
            )
            this.updateContractState()
            this.whitelisting = false
        },
        async clearVoucherAddress() {
            this.voucherAddress = ""
        },
        async checkVoucherAddress() {
            this.voucherCheckAddress = this.voucherCheckAddress.trim()
            try   { ethers.utils.getAddress(this.voucherCheckAddress) }
            catch { return this.showToast('danger', 'Invalid address', `Address(${this.voucherCheckAddress}) is invalid`) }
            let ret = await this.contract.getVoucherAmount(this.voucherCheckAddress)
            alert(ret)
        },
        async run(name, cmd) {
            try {
                this.showToast('info', 'Sending for signing', `${name} transaction is send for signing`)
                let tx = await cmd
                this.showToast('info', 'Transaction sent', `${name} transaction is successfully sent with hash ${tx.hash}`)
                let receipt = await tx.wait()
                this.showToast('succcess', 'Transaction complete', `${name} transaction is completed. Blockhash: ${receipt.blockHash}. TxHash: ${receipt.transactionHash}`)
            } catch (e) {
                console.log(e)
                this.showToast('danger', 'Transaction failed', e.message)
            }            
        },
        showToast: function(variant, title, message) {
            this.logToast(variant, title, message)
            this.$toast({
                component: ToastificationContent,
                props: { title: title, text: message, variant },
            })
        },       
    },
    mounted: function() {
        this.initWeb3Modal()
        this.updateConnectionStatus()        
    },
    components: {
        BCardText,
        BCardActions,
        BCol, BRow, BTabs, BTab, BButton, BFormSelect, BFormGroup, BFormInput, BInputGroup, BFormTextarea, BForm,
        BInputGroupPrepend,
        ButtonSpinner,
        VoucherMintView,
        VoucherUpdateView,
        VoucherDetailView,
        ToastificationContent,
    },
    watch: {
        isUserConnected : function() { this.updateConnectionStatus() },
        getActiveAccount: function() { this.updateConnectionStatus() },
        getChainId: function() { this.updateConnectionStatus() },
        getContract: function() { this.updateConnectionStatus() },
        address: function() { this.updateContractState() },
    },
    directives: {
        Ripple,
    },    
}
</script>